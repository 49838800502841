import staffImage from "../assets/staff.png";
import daniel from "../assets/daniel.png";
import david from "../assets/david.png";
import fortune from "../assets/fortune.png";
import joseph from "../assets/joseph.png";
import nancy from "../assets/nancy.png";
import samuel from "../assets/samuel.png";
import victor from "../assets/victor.png";
import victory_rukky from "../assets/victory_rukky.png";
import victory from "../assets/victory.png";
import saurav from "../assets/saurav.png";
import hope from "../assets/hope.png";
import harry from "../assets/harry.png";

export const EMPLOYEE__PROFILE = [
  {
    id: 1,
    image: hope,
    name: "Hope Opus",
    position: "Project Manager | Business Analyst | Software Developer",
    description:
      `Hope Opus is a dynamic and versatile professional with expertise in Project Management, Business Analysis, and Software Development. With a strong background in delivering innovative digital solutions, Hope brings a unique blend of technical proficiency and strategic insight to every project. As a Project Manager, Hope excels at overseeing complex projects from inception to completion, ensuring they are delivered on time, within scope, and aligned with client goals.

As a Business Analyst, Hope specializes in analyzing organizational processes and systems, identifying areas for improvement, and implementing tailored solutions that drive business success. With hands-on experience in Software Development, Hope has led the creation of cutting-edge applications and systems that enhance operational efficiency and customer engagement.

At Geetek Global, Hope is committed to driving digital transformation and delivering high-quality, scalable solutions that empower clients to achieve their business objectives.`,
  },
  {
    id: 2,
    image: victory_rukky,
    name: "Ms.ORU VICTORY (RUKKY)",
    position: "OPERATIONS ASSISTANT",
    description:
      "Ms. Oru Victory as the operations Assistant ensures the smooth sailing of all operations in GeeTek Global, Managing Project deadlines,  budget and ensuring productivity amount staff members.",
  },
  {
    id: 3,
    image: samuel,
    name: "Olamide Samuel",
    position: "Design Manager",
    description:
      "Samuel Olamide is a versatile UI/UX and brand designer with over 3 years of experience in creating intuitive, user-friendly digital experiences and more than 5 years in developing impactful brand identities. Currently working with Geetek Global, Samuel combines creativity with strategic thinking to deliver designs that look great and solve real-world problems. With a deep understanding of design principles and a passion for innovation, he consistently crafts solutions that elevate both brands and user experiences.",
  },
  {
    id: 4,
    image: saurav,
    name: "Saurav T Ajith",
    position: "Software Development Manager",
    description:
      "Saurav T Ajith is a skilled software engineer and team leader with over 3 years of experience and growing expertise in building scalable, high-performance applications. At Geetek Global, he combines his technical skills with leadership to manage a team of developers, delivering innovative and impactful projects. Saurav has a strong foundation in full-stack development, creating efficient web, mobile, and backend systems, while also handling API development, cloud integration, and database management. He has written CI/CD pipelines to automate deployment processes and enhance project efficiency. Committed to continuous learning and staying ahead of industry trends, Saurav drives his team to solve complex challenges and push the boundaries of technology.",
  },
  {
    id: 5,
    image: victor,
    name: "Victor",
    position: "Graphic Designer | Motion Designer",
    description:
      "As a skilled Graphic and Motion Designer at GEETEK Global, Victor blends creativity and precision to craft stunning visual content. With a keen eye for detail and a passion for storytelling through design, he transforms ideas into engaging visual experiences, whether through captivating graphics or dynamic motion designs. Victor is dedicated to delivering impactful work that aligns with brand goals while continuously exploring innovative design techniques.",
  },
  {
    id: 6,
    image: harry,
    name: "Harry Azamosa",
    position: "Software Developer",
    description:
      "Harry Azamosa is a skilled full stack software developer with expertise in both front-end and back-end development. With a deep understanding of modern technologies, he has successfully built and deployed web, mobile and backend applications that are efficient, scalable, and user-friendly. He is proficient in creating robust APIs, integrating cloud services, and using various databases. Harry is also passionate about continuous learning and staying updated with the latest trends in software development to deliver innovative solutions for complex problems. His experience spans various industries showcasing his versatility and commitment to high-quality development.",
  },
  {
    id: 7,
    image: nancy,
    name: "Jaja Nancy Nengi",
    position: "HR intern",
    description:
      "Jaja Nancy Nengi, is a driven and enthusiastic HR Intern dedicated to delivering exceptional support and solutions. With a strong passion for learning and growth, Nancy excels in fast-paced environments, quickly absorbing knowledge and expertise to drive HR excellence. Collaborative, organized, and results-focused, Nancy is committed to fostering a positive and productive work environment.",
  },
  {
    id: 8,
    image: fortune,
    name: "Fortune Emebike",
    position: "Content Writer",
    description:
      "Fortune Emenike is a content writer with a passion for crafting engaging and compelling stories. With a keen eye for detail, capturing the essence of brands. She specializes in creating content that resonates with audiences and her writing style blends creativity with strategic thinking to deliver the right message that drives engagement and inspires action. She creates diverse content across various platforms, from blog posts and articles to social media campaigns and email newsletters. Her ability to adapt to tones and styles to suit different audiences sets her apart as a versatile writer capable of meeting the unique needs of each project. With a commitment to continuous learning and staying updated on industry trends, she strives to bring fresh perspectives and innovative ideas to every piece she writes as her goal always is to deliver content that informs, entertains, and inspires. She is intentionally dedicated to helping brands tell their stories in a way that resonates with their target audience and drives results.",
  },
  {
    id: 9,
    image: david,
    name: "David Effanga",
    position: "Intern Developer",
    description:
      "David Effanga is a skilled front-end developer with experience in full stack web development. Having successfully completed several full stack projects, David has a deep understanding of  front and basic knowledge of back ends of web applications. In addition to his coding expertise, he also has a strong foundation in basic design work, allowing him to create visually appealing and user-friendly interfaces. David’s passion for technology and eye for design makes him versatile in delivering high-quality digital solutions.",
  },
  {
    id: 10,
    image: joseph,
    name: "Joseph Ashi Utam",
    position: "Digital Marketer",
    description:
      "As a seasoned digital marketer, I help drive business growth through tailored online solutions. With expertise in SEO, PPC, Social Media, Email Marketing, Content Strategy, Influencer Partnerships, Analytics, and Conversion Rate Optimization, I develop and execute strategies that boost engagement, conversion and drive growth. I am passionate about innovation and customer-centricity, I stay ahead of industry trends to deliver amazing results for our clients. Let's work together to elevate your online presence.",
  },
  {
    id: 11,
    image: daniel,
    name: "Daniel Mathew",
    position: "Sales Executive",
    description:
      "Daniel Mathew is a dynamic Sales Executive, I leverage my expertise and industry knowledge to foster strong client relationships and drive revenue growth. With a passion for technology and a commitment to customer satisfaction, I consistently meet sales targets and delivers exceptional results. My ability to effectively communicate technical concepts and build trust with clients has made me such a valuable asset.",
  },
  {
    id: 12,
    image: victory,
    name: "Victory Eze Damasus",
    position: "Digital Marketer",
    description:
      "Victory Eze is a passionate digital marketer specializing in helping brands build a strong online presence and connect with their audience. Committed to constant learning and growth, Victory leverages social media as a powerful tool for brands to engage their audience while driving growth and boosting brand awareness.",
  },
];
