import React from 'react'
import './home-3.css'

function Home3() {
  return (
    <div className='home3'>
        <div className="home3__container">
            <div className="home3__section">
                <div className="home3__left">
                    <div className="home3__header">
                        <p className="home3__header-text">
                            Industries we've worked on
                        </p>
                    </div>
                    <div className="home3__industries">
                    <div className="home3__industry-group">
                        <div className="home3__industry">
                            <p className="home3__industry-text">Saas</p>
                        </div>
                        <div className="home3__industry">
                            <p className="home3__industry-text">Medical</p>
                        </div>
                        <div className="home3__industry">
                            <p className="home3__industry-text">Marketing</p>
                        </div>
                    </div>
                    <div className="home3__industry-group">
                        <div className="home3__industry">
                            <p className="home3__industry-text">Commerce</p>
                        </div>
                        <div className="home3__industry">
                            <p className="home3__industry-text">Food</p>
                        </div>
                        <div className="home3__industry">
                            <p className="home3__industry-text">Medical</p>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="home3__right">
                    <div className="home3__amazing">
                        <p className="home3__amazing-text1">
                        Here are some of
                        </p>
                        <p className="home3__amazing-text2">
                        the websites we've
                        </p>
                        <p className="home3__amazing-text3">
                        designed and built
                        </p>
                    </div>
                    <div className="home3__image-container">
                        <img src="/images/stroke.svg" alt="stroke" className="home3__image"/>
                        <img src="/images/stroke-mobile.svg" alt="stroke" className="home3__image-mobile"/>
                    </div>
                </div>
            </div>
            <div className="home3__cards">
                <div className="home3__card home3__card--first">
                    <div className="home3__card-left">
                        <div className="home3__card-image-container">
                            <img src="/images/brandforgex-1.svg" alt="stroke" className="home3__card-image"/>
                        </div>
                        <div className="home3__card-text-container-1">
                            <p className="home3__card-text-1">
                                UI UX | Responsive UX
                            </p>
                        </div>
                        <div className="home3__card-text-container-2">
                            <p className="home3__card-text-2">
                                Our vision is to revolutionize the way brands and advertisers target, reach
                            </p>
                        </div>
                    </div>
                    <div className="home3__card-right">
                        <img src="/images/brandforgex-2.svg" alt="stroke" className="home3__card-image-lap-1"/>
                        <img src="/images/brandforgex-3.svg" alt="stroke" className="home3__card-image-lap-2"/>
                        <img src="/images/brandforgex-mobile.svg" alt="stroke" className="home3__card-image-mobile"/>
                    </div>
                </div>
                <div className="home3__card home3__card--second">
                    <div className="home3__card-left">
                        <div className="home3__card-image-container">
                            <img src="/images/groce-1.svg" alt="stroke" className="home3__card-image"/>
                        </div>
                        <div className="home3__card-text-container-1">
                            <p className="home3__card-text-1">
                                UI UX | Responsive UX
                            </p>
                        </div>
                        <div className="home3__card-text-container-2">
                            <p className="home3__card-text-2">
                            A grocery mobile store to help users get their groceries deliver to them at ease
                            </p>
                        </div>
                    </div>
                    <div className="home3__card-right">
                        <img src="/images/groce-2.svg" alt="stroke" className="home3__card-image-lap-1"/>
                        <img src="/images/groce-3.svg" alt="stroke" className="home3__card-image-lap-2"/>
                        <img src="/images/groce-mobile.svg" alt="stroke" className="home3__card-image-mobile"/>
                    </div>
                </div>
                <div className="home3__card home3__card--third">
                    <div className="home3__card-left">
                        <div className="home3__card-image-container">
                            <img src="/images/geetekhub-1.svg" alt="stroke" className="home3__card-image"/>
                        </div>
                        <div className="home3__card-text-container-1">
                            <p className="home3__card-text-1">
                                UI UX | Responsive UX
                            </p>
                        </div>
                        <div className="home3__card-text-container-2">
                            <p className="home3__card-text-2">
                            A Saas software to foster communication across team members and manage projects and Marketing
                            </p>
                        </div>
                    </div>
                    <div className="home3__card-right">
                        <img src="/images/geetekhub-2.svg" alt="stroke" className="home3__card-image-lap-1"/>
                        <img src="/images/geetekhub-mobile.svg" alt="stroke" className="home3__card-image-mobile"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home3