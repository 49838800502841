import React from 'react';
import Navbar from '../../components/Navbar/navbar';
import './home-1.css';

function Home1() {
  return (
    <div className='home'>
      <Navbar />
      <div className='background'>
        <div className='horizontal-lines'></div>
        <div className='vertical-lines'></div>
      </div>
      <div className="containers">
      <div className="text-containers">
        <div className="first-text">
         <p className="we-text">We are</p>
         <div className="pencil-container">
         <p className="thinkers-text"> Thinkers,</p>
          <img src="images/pencil.svg" alt="pencil" className='pencil-image'/>
        </div>
        </div>
        <div className="second-text">
          <div className="design-containers">
          <img src="images/palatte.svg" alt="palatte" className='palatte-image'/>
          <p className="design-text">Designers,</p>
          </div>
          <div className="developer-containers">
          <p className="developer-text">Developers</p>
          <img src="images/tags.svg" alt="tags" className='tags-image'/>
          </div>
        </div>
        <div className="third-text">
        <img src="images/pricing.svg" alt="pricing" className='pricing-image'/>
          <p className="think-text">* Just think</p>
          <p className="build-text">* we build</p>
        <img src="images/scribble.svg" alt="scribble" className='scribble-image'/>
        </div>
      </div>
      <div className="subtext-contianer">
        <div className="subtext">
        At Geetek Global, we are passionate about providing innovative software solutions to help businesses thrive in the digital age. Our team of experts are dedicated to assist you in achieving your goals, whether it’s optimizing your online presence, implementing effective digital marketing strategies, or enhancing your brand identity.
        </div>
      </div>
      <div className="trusted-contianer">
        <div className="trusted-text">
        Trusted Brands
        </div>
        <div className="trusted-images">
          <img src="images/brandforgex.svg" alt="brand" className='brand-image'/>
          <img src="images/slack.svg" alt="brand" className='brand-image'/>
          <img src="images/netflix.svg" alt="brand" className='brand-image'/>
          <img src="images/google.svg" alt="brand" className='brand-image'/>
          <img src="images/airbnb.svg" alt="brand" className='brand-image'/>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Home1;